import React from 'react';
import Construction from '../construction';

const Machine = () => {
  return (
    <Construction title={'Machine Page'} />
  );
};

export default Machine;
