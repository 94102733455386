exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FLGI-5Or26ZcD-7VVawcr {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  flex-direction: row; }\n\nspan {\n  margin-left: 10px; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "FLGI-5Or26ZcD-7VVawcr"
};