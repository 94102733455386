exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Yk45A0Tsz5zW9RJbiYNe- {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column; }\n\n._3D5E9_vtO7Q8_C4j_mHyiM {\n  text-align: center; }\n  @media screen and (min-width: 1000px) {\n    ._3D5E9_vtO7Q8_C4j_mHyiM {\n      width: 402px; } }\n\n._3Yk45A0Tsz5zW9RJbiYNe- > * {\n  margin-top: 40px;\n  margin-bottom: 40px; }\n\n._1IggQMkCYAuHzr2NXndOcd {\n  padding: 30px;\n  border-radius: 50px; }\n\n._1HGByGTzZqC__qPYpzMP2C {\n  display: flex;\n  justify-content: center;\n  width: 100%; }\n\nh1, h2, h3, h4 {\n  text-align: center; }\n\nspan {\n  text-align: center; }\n", ""]);

// exports
exports.locals = {
	"centered": "_3Yk45A0Tsz5zW9RJbiYNe-",
	"inputGroup": "_3D5E9_vtO7Q8_C4j_mHyiM",
	"button": "_1IggQMkCYAuHzr2NXndOcd",
	"fullWidth": "_1HGByGTzZqC__qPYpzMP2C"
};