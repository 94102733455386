exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".e87rG2cYABjVV7ZEyxrt4 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center; }\n\n.e87rG2cYABjVV7ZEyxrt4 > * {\n  margin-top: 15px; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "e87rG2cYABjVV7ZEyxrt4"
};