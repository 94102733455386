exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3mH59ndI68YLVQSwP0TW5H {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around; }\n  @media screen and (min-width: 1000px) {\n    ._3mH59ndI68YLVQSwP0TW5H {\n      width: 50%;\n      margin: auto; } }\n", ""]);

// exports
exports.locals = {
	"wrapper": "_3mH59ndI68YLVQSwP0TW5H"
};