exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1E_Z5nc7nnnIWOqvEAuwmI {\n  display: flex;\n  justify-content: center;\n  flex-direction: row;\n  width: fit-content;\n  margin: auto;\n  color: inherit; }\n  ._1E_Z5nc7nnnIWOqvEAuwmI:hover {\n    text-decoration: none; }\n\n._3D2cPjbWAPiksHqo8DWr3l {\n  display: flex;\n  align-items: flex-end; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "_1E_Z5nc7nnnIWOqvEAuwmI",
	"appName": "_3D2cPjbWAPiksHqo8DWr3l"
};