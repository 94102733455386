exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".wmyqVrjdc0tU9zQZUhm3L {\n  display: grid;\n  height: 100%;\n  grid-gap: 10px;\n  grid-template-rows: 13% 80% 5%;\n  grid-template-columns: auto 90% auto;\n  grid-template-areas: \" . header . \" \" . content . \" \" . footer . \"; }\n  @media screen and (min-width: 768px) {\n    .wmyqVrjdc0tU9zQZUhm3L {\n      grid-template-columns: auto 65% auto; } }\n\n._1wrzeXsNzrJ7ZI1ovOHbvw {\n  display: flex;\n  align-items: center;\n  grid-area: header; }\n\n._1-cVgnb-INKXthEacrUDPs {\n  grid-area: footer; }\n\n.c2W3_nIYgNyH3-V3bY-nF {\n  display: flex;\n  justify-content: center;\n  grid-area: content;\n  max-height: 100%; }\n", ""]);

// exports
exports.locals = {
	"App": "wmyqVrjdc0tU9zQZUhm3L",
	"header": "_1wrzeXsNzrJ7ZI1ovOHbvw",
	"footer": "_1-cVgnb-INKXthEacrUDPs",
	"content": "c2W3_nIYgNyH3-V3bY-nF"
};