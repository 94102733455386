import React from 'react';
import Construction from '../construction';

const About = () => {
  return (
    <Construction title={'About Page'} />
  );
};

export default About;
