exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1thlmQ8mIgckQMX1zi3D7_ {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 90%; }\n\n._2EwmBBX7WVucmUt6CKaWN0 {\n  padding: 30px;\n  border-radius: 50px; }\n\n._1thlmQ8mIgckQMX1zi3D7_ > * {\n  margin-top: 40px;\n  margin-bottom: 40px; }\n\n.rEe2U96wZYDxoopXOs7gS {\n  width: 100%; }\n", ""]);

// exports
exports.locals = {
	"centered": "_1thlmQ8mIgckQMX1zi3D7_",
	"button": "_2EwmBBX7WVucmUt6CKaWN0",
	"formGroup": "rEe2U96wZYDxoopXOs7gS"
};