exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".iMuvP8zStW9YXbR8i_WuL {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 20px; }\n\n._1De0jg4Dm1liaqZ0ugtC-Z {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-left: auto;\n  margin-right: auto;\n  width: 50%; }\n\n._344dlZpP25jYa0dfmjzqlb {\n  display: flex;\n  align-items: cetner;\n  justify-content: center;\n  width: 100%;\n  height: 60px;\n  text-align: center;\n  background-color: #c3dda5;\n  margin-bottom: 2em;\n  border-radius: 50px;\n  color: #243510;\n  cursor: default; }\n  ._344dlZpP25jYa0dfmjzqlb:hover {\n    background-color: red; }\n    ._344dlZpP25jYa0dfmjzqlb:hover span {\n      color: yellow;\n      cursor: default; }\n  @media screen and (min-width: 1000px) {\n    ._344dlZpP25jYa0dfmjzqlb {\n      width: 60%;\n      margin-bottom: 4em; } }\n\nspan {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #243510; }\n", ""]);

// exports
exports.locals = {
	"buttonWrapper": "iMuvP8zStW9YXbR8i_WuL",
	"homeNav": "_1De0jg4Dm1liaqZ0ugtC-Z",
	"button": "_344dlZpP25jYa0dfmjzqlb"
};